
import { makeDateTextFormatKo } from '@/utils/dateFnsUtils.js';

export default {
  name: 'ReviewAdmin',
  props: {
    adminComment: { type: String, default: '' },
    adminCommentedAt: { type: String, default: '' },
  },
  data() {
    return {
      adminIcon: require('@/assets/img/review/gguge-admin.png'),
    };
  },
  computed: {
    adminCommentedAtString: ({ adminCommentedAt }) => {
      return adminCommentedAt
        ? makeDateTextFormatKo(
            new Date(adminCommentedAt),
            'yyyy. M. d. aa h:mm:ss',
          )
        : '-';
    },
  },
};
