
import ReviewAdmin from './ReviewAdmin.vue';
import ReviewTeacher from './ReviewTeacher.vue';

export default {
  name: 'ReviewComments',
  components: { ReviewAdmin, ReviewTeacher },
  props: {
    adminCommentedAt: { type: String, default: '' },
    teacherCommentedAt: { type: String, default: '' },
    adminComment: { type: String, default: '' },
    teacherComment: { type: String, default: '' },
    teacher: { type: Object, required: true },
    isTeacherCommentBlind: { type: Boolean, default: false },
    teacherCommentDeletedAt: { type: String, default: '' },
  },
};
