
import { makeDateTextFormatKo } from '@/utils/dateFnsUtils.js';
import RoundAvatar from '../common/img/RoundAvatar.vue';
import InvisibleReview from './InvisibleReview.vue';
export default {
  name: 'ReviewTeacher',
  components: { RoundAvatar, InvisibleReview },
  props: {
    teacherComment: { type: String, default: '' },
    teacherCommentedAt: { type: String, default: '' },
    teacher: { type: Object, required: true },
    isTeacherCommentBlind: { type: Boolean, default: false },
  },
  computed: {
    teacherCommentedAtString: ({ teacherCommentedAt }) => {
      return teacherCommentedAt
        ? makeDateTextFormatKo(
            new Date(teacherCommentedAt),
            'yyyy. M. d. aa h:mm:ss',
          )
        : '-';
    },
  },
};
